import "./App.css";
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { useEffect } from "react";
import { isMobile} from 'react-device-detect';

const firebaseConfig = {
  apiKey: "AIzaSyCTGWpV-ErlEBY0KyM2U10FSz_HCWmS0xU",
  authDomain: "com-adoraboo.firebaseapp.com",
  projectId: "com-adoraboo",
  storageBucket: "com-adoraboo.appspot.com",
  messagingSenderId: "593592380076",
  appId: "1:593592380076:web:53deb2dadb0ec046564d09",
  measurementId: "G-YRCTVDMVSY"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const DOWNLOAD_LINK = {
  android: "https://play.google.com/store/apps/details?id=com.adoraboo&referrer=utm_source%3Dwebsite_gp",
  ios: "https://apps.apple.com/app/id6478853088", //todo 更换为cpp链接
};

function App() {
  /**
   * @param {String} platform  "GP" | "IOS"
   */
  const handleDownload = (platform) => {
    const url = platform === "GP" ? DOWNLOAD_LINK.android : DOWNLOAD_LINK.ios;
    url && window.location.assign(url);
    logEvent(analytics, 'download_click', {"platform":platform, "link" : url});
  };

  useEffect(() => {
    logEvent(analytics, 'app_start', {"user-agent":navigator.userAgent});
    const url = window.location.href;
    if (isMobile && ['add/', 'help_recall'].filter((e) => url.includes(e)).length > 0) {
      const deeplink = url.replace('http://' + window.location.host, 'adoraboo://jump.to').replace('https://' + window.location.host, 'adoraboo://jump.to');
      console.log(deeplink);
      window.location.href = deeplink;
    }
  })
  return (
    <div id="adoraboo-wrapper">
      <div className="logo">
        <img src={require("./images/logo.png")} alt="" />
      </div>
      <div className="slogen_img">
        <img src={require("./images/slogen.png")} alt="" />
      </div>
      {/* <div className="slogen">Your Boo, My Boo, <br/>Adoraboo!</div> */}
        <div
            className="download-ios"
            onClick={() => {
                handleDownload("IOS");
            }}
        >
        </div>
        <div
            className="download-android"
            onClick={() => {
                handleDownload("GP");
            }}
        >
        </div>

      <footer>
        <div className="copyright">
        Copyright © 2024 Happeny Technology Pte. Ltd.
        </div>

        
        <a className="email" href="mailto:official@adoraboo.net">Email:official@adoraboo.net</a>

        <div className="link-wrapper">
          <a className="link-policy" href="/privacy.html">
            Privacy Policy
          </a>
          <span className="split">|</span>
          <a className="link-terms" href="/terms.html">
            Terms of Service
          </a>
        </div>
      </footer>
    </div>
  );
}

export default App;
